import React from 'react';

import TestimonialsSection from '../../../small_views/testimonials_section/testimonials_section';

import BackgroundImage from '../../../../assets/images/pages/solutions/agri/testimonials_section/background/background.jpg';
import BackgroundImageLowDef from '../../../../assets/images/pages/solutions/agri/testimonials_section/background/background_low_def.jpg';

import data from './agri_testimonials_data';

import classes from './agri_testimonials_section.module.css';

const AgriTestimonialsSection = () => (
	<TestimonialsSection
		{...{ data }}
		title="Ils ne peuvent plus s'en passer"
		background={BackgroundImage}
		backgroundLowDef={BackgroundImageLowDef}
	/>
)

export default AgriTestimonialsSection;
