import SpeedIcon from '../../../../assets/images/views/interactive_view_section/icons/vitesse.svg'
import CartographicReportIcon from '../../../../assets/images/views/interactive_view_section/icons/rapportcartographique.svg'
import CartographicReportImage from '../../../../assets/images/views/interactive_view_section/desktop_images/cuma-rapportcartographique/cuma-rapportcartographique.png'
import CartographicReportImageLowDef from '../../../../assets/images/views/interactive_view_section/desktop_images/cuma-rapportcartographique/cuma-rapportcartographique_low_def.png'
import SurfaceControlIcon from '../../../../assets/images/views/interactive_view_section/icons/controledesurfaces.svg'

const interactiveDesktopData = {
	speed: {
		icon: SpeedIcon,
		label: 'Vitesse des matériels',
		description: `Localisez les matériels de votre CUMA en toute simplicité;

    et visualisez l'activité de chacun d'entre eux d'un simple coup d'œil`,
		correspondingViewImage: CartographicReportImage,
		correspondingViewImageLowDef: CartographicReportImageLowDef
	},
	cartographicReport: {
		icon: CartographicReportIcon,
		label: 'Rapport cartographique',
		description: `Localisez les matériels de votre CUMA en toute simplicité;

    et visualisez l'activité de chacun d'entre eux d'un simple coup d'œil`,
		correspondingViewImage: CartographicReportImage,
		correspondingViewImageLowDef: CartographicReportImageLowDef
	},
	surfaceControl: {
		icon: SurfaceControlIcon,
		label: 'contrôle des surfaces',
		description: `Localisez les matériels de votre CUMA en toute simplicité;

    et visualisez l'activité de chacun d'entre eux d'un simple coup d'œil`,
		correspondingViewImage: CartographicReportImage,
		correspondingViewImageLowDef: CartographicReportImageLowDef
	}
}

export default interactiveDesktopData
