import ThierryBailletPicture from '../../../../assets/images/pages/solutions/agri/testimonials_section/thierry_baillet/thierry_baillet.png';
import ThierryBailletPictureLowDef from '../../../../assets/images/pages/solutions/agri/testimonials_section/thierry_baillet/thierry_baillet_low_def.png';

import SandrineLeFeur from '../../../../assets/images/pages/solutions/agri/testimonials_section/sandrine_le_feur/sandrine_le_feur.png';
import SandrineLeFeurLowDef from '../../../../assets/images/pages/solutions/agri/testimonials_section/sandrine_le_feur/sandrine_le_feur_low_def.png';

export default {
	thierryBaillet: {
		url: 'https://www.youtube.com/watch?v=IgnfKt6yojM',
		tag: 'Nord-Pas-De-Calais',
		name: 'Thierry Baillet',
		title: 'Agriculteur Bio',
		preview: ThierryBailletPicture,
		previewLowDef: ThierryBailletPictureLowDef,
    videoDuration: '3:05'
	},
	sandrineLeFeur: {
		url: 'https://www.youtube.com/watch?v=q8IUpZt0ros',
		tag: 'SUD OUEST',
		name: 'Sandrine Le Feur',
		title: 'Agricultrice Bio',
		preview: SandrineLeFeur,
		previewLowDef: SandrineLeFeurLowDef,
    videoDuration: '2:22'
	}
};
