import React from 'react';

import InteractiveViewSection from '../../../small_views/interactive_view_section/interactive_view_section';
import DefaultActionsButtons from '../../../small_views/interactive_view_section/default_actions_buttons/default_actions_buttons';

import data from './agri_interaction_desktop_data';

import classes from './agri_interaction_desktop_section.module.css';

const AgriInteractiveDesktopSection = () => (
	<InteractiveViewSection
		reverseContent
		sectionClassName={classes.section}
		viewType="desktop"
		title="Toujours plus de fonctionnalités"
		{...{ data }}
	>
		<DefaultActionsButtons />
	</InteractiveViewSection>
);

export default AgriInteractiveDesktopSection;
