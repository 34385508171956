import React from 'react';

import InteractiveViewSection from '../../../small_views/interactive_view_section/interactive_view_section';

import classes from './agri_interaction_phone_section.module.css';

const AgriInteractivePhoneSection = () => (
	<InteractiveViewSection
		showDownloadsLinks
		viewType="phone"
		viewDeviceProps={{
			className: classes.viewDevice
		}}
	/>
);

export default AgriInteractivePhoneSection;
